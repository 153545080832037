import { post , get } from '../request'

const itgs = {
    // ==============================微信部分===========================
    getConfig(data){return post('/wx/sign',data);},
    //==============================首页部分===========================
    // 获取所有项目
    // getProject(data){return post('/web/itgsProject/getProjectsByUser',data)},
    // 根据项目编号获取当下设备列表
    getProjectDev(data){return post('/itgs/exec/wxDevices/getDevByProjCode',data)},
    // getUserAuth(params){return get('/itgs/exec/wxItgsWxUserVsProject/getUserProject',params)},// 获取用户权限 游客或项目管理者
    getStat(params){return post('/itgs/exec/wxDevices/getStat',params)},
    // getDevInfoPage(pageNum,pageSize,data){return post('/itgs/exec/wxDevices/getDevInfo4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},   //历史数据 设备状态同一接口
    getDevInfoPage(data){return post('/itgs/exec/deviceBaseInfo/getDevStatus',data)},   //历史数据 设备状态同一接口
    getAuth(data){return post('/itgs/exec/wxItgsDevFunction/getOne',data)},  // 获取控制权限
    // getStatus(url){return get('/itgs/exec/wxDevices/getStatus/'+url)},//最新一条设备状态------（待废弃）-----

    get720CStat(devCode){return get('/itgs/exec/wxItgsMonitorDevsta/getLast/'+devCode)}, // 720C设备状态
    //==============================详情权限管理部分===========================
    devsta(url){return get('/itgs/exec/wxItgsMonitorDevsta/getByDevCode/'+url)}, //设备状态A
    appBeat(url){return get('/itgs/exec/wxItgsMonitorAppBeat/getByDevCode/'+url)}, //设备状态B
    getWeather(url){return get('/itgs/exec/wxItgsMonitorEnv/getEnv/'+url)},  //气象数据
    // getCarStat(url){return get('/itgs/exec/wxItgsMonitorCarStat/getCarStat/'+url)},//过车统计数据
    carInfo(url){return get('/itgs/exec/wxItgsMonitorCar/getByDevCode/'+url)}, // 单车数据
    // carPeriod(url){return get('/itgs/exec/wxItgsMonitorCarPeriodData/getByDevCode/'+url)},//车辆周期数据
    // carFlowType(url){return get('/itgs/exec/wxItgsMonitorCarFlowType/getByDevCode/'+url)},//分车型流量数据
    // truckFlowLane(url){return get('/itgs/exec/wxItgsMonitorTruckFlowLane/getByDevCode/'+url)},//分车道货车流量数据
    // busFlowLane(url){return get('/itgs/exec/wxItgsMonitorBusFlowLane/getByDevCode/'+url)}, //分车道客车流量数据
    // roadStatus(url){return get('/itgs/exec/wxItgsMonitorRoadStatus/getByDevCode/'+url)}, //路面状态数据
    // waterFreezeDevsta(url){return get('/itgs/exec/wxItgsMonitorWaterFreezeDevsta/getByDevCode/'+url)},// 路面设备数据
    //==============================位置部分===========================
    getAllDevInfo(data){return post('/itgs/exec/wxDevices/getAllDevInfo',data)},
    //==============================媒体部分===========================
    getMediaFile(pageNum,pageSize,data){return post('/itgs/exec/wxDevFile/getMediaFile?pageNum='+pageNum+'&pageSize='+pageSize,data)},
    //==============================工作台部分===========================
    //==============================告警数据部分===========================
    AlarmCarStop(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmCarStop/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 应急车道停车事件告警
    AlarmDamage(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmDamage/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 倾角告警数据
    AlarmDevFault(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmDevFault/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 设备故障告警
    AlarmFence(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmFence/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)}, // 电子围栏事件告警
    AlarmHelp(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmHelp/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 现场求助事件告警
    AlarmPowerDown(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmPowerDown/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 设备掉电事件告警
    AlarmPowerLow(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmPowerLow/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 设备缺电告警
    AlarmProLost(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmProLost/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 节目单丢失告警
    // AlarmWaterFreese(pageNum,pageSize,data){return post('/itgs/exec/wxItgsAlarmWaterFreese/getAlarm4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},// 积水结冰告警数据
    //==============================气象数据部分===========================
    MonitorEnv(pageNum,pageSize,data){return post('/itgs/exec/wxItgsMonitorEnv/getEnv4Page?pageNum='+pageNum+'&pageSize='+pageSize,data)},
    //==============================过车数据部分===========================
    // MonitorCarStat(pageNum,pageSize,data){return post('/itgs/exec/wxItgsMonitorCarStat/getCarStatList?pageNum='+pageNum+'&pageSize='+pageSize,data)},
    //==============================控制选项部分===========================
    getMonitorProgramList(data){return get('/itgs/exec/wxProgram/getMonitorProgramList',data)},
    getProgramList(data){return post('/itgs/exec/wxProgram/getProgramList',data)},
    getControlDict(data){return post('/itgs/exec/wxSysDict/getDict',data)},
    // getControlPhoto(data){return post('/itgs/exec/wxSysDict',data)},
    getAppBeat(data){return post('/itgs/exec/wxItgsMonitorAppBeat/getMonitorAppBeat',data)}, // 获取最新设备选项
    //==============================提交控制选项部分===========================
    subProgram(data){return post('/itgs/exec/wxItgsControlDevShow/save',data)}, // 单个节目控制
    subProgramBatch(data){return post('/itgs/exec/wxItgsControlDevShow/saveBatch',data)}, // 多个节目控制
    subLight(data){return post('/itgs/exec/wxItgsControlDevLight/save',data)}, // 单个灯光控制
    subLightBatch(data){return post('/itgs/exec/wxItgsControlDevLight/saveBatch',data)}, // 多个灯光控制
    subMedia(data){return post('/itgs/wxItgsControlDevMedia/save',data)}, // 单个实时拍照控制
    subMediaPeriod(data){return post('/itgs/wxItgsControlDevMediaPeriod/save',data)}, // 单个周期拍照控制
    subMultMedia(data){return post('/itgs/wxItgsControlMultiMdeia/save',data)}, // 单个直播控制
    syncUrl:'/itgs/wxItgsControlMultiMdeia/save' // 原生xmlhttp直播控制
}
export default itgs