<template>
    <div class="setting-home">
        <div class="banner">
            <div class="hint">
                <div class="logo"><img :src="headImgUrl"></div>
                <div>{{ nickname || '加载中...'}}</div>
            </div>
        </div>
        <div class="info">
            <div class="inner">
                <van-cell title="模板消息" is-link @click="openTempMsg"/>
                <van-cell title="切换账户" is-link @click="exit" />
                <van-cell title="版本号" value="v1.0.0" @click="version"/>
            </div>
        </div>
        <!-- 自定义面板 -->
        <van-popup v-model="tempMsgShow" round position="bottom" :style="{ height: '65%' }" >
            <div class="content-action">
                <van-collapse v-model="activeNames">
                    <van-collapse-item :title="pItem.keyName" :name="pIndex.toString()"  v-for="(pItem,pIndex) in proData" :key="pIndex">
                        <div>
                            <van-checkbox-group v-model="resTempMsg">
                                <van-cell-group>
                                    <van-cell
                                    v-for="(item, index) in pItem.val"
                                    clickable
                                    :key="item.devOnly"
                                    :title="`${item.projName}${item.projDetail}`"
                                    @click="toggle(index,pIndex)"
                                    >
                                    <template #right-icon>
                                        <van-checkbox :name="item.devOnly" :ref="`checkboxes-${pIndex}-${index}`" />
                                    </template>
                                    </van-cell>
                                </van-cell-group>
                            </van-checkbox-group>
                        </div>
                    </van-collapse-item>
                </van-collapse>
                <div class="handle-area">
                    <div class="btn-submit" @click="saveTempMsg">保存修改</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { appId } from '@/config/weixin.js'

export default {
    data(){
        return {
            tempMsgShow:false,
            proData: [],
            resTempMsg: [],
            activeNames: [],
            OPENID:null,
            headImgUrl:null,
            nickname:null,
        }
    },
    methods:{
        // 打开模板消息框
        openTempMsg(){
            this.tempMsgShow = true
        },
        toggle(index,pIndex) {
            this.$refs[`checkboxes-${pIndex}-${index}`][0].toggle();
        },
        // 退出登录
        exit(){
            this.$dialog.confirm({
                message: '退出当前账户？',
            })
            .then(() => {
                this.$api.SSO.exitUser({
                    openId:this.OPENID
                }).then( () => {
                    window.sessionStorage.clear();
                    window.localStorage.removeItem('visitor')  // 清空游客标志位
                    this.$router.push({path:'/login'})
                })
            })
            .catch(() => {});
        },
        version(){
            this.$toast('当前是最新版本');
        },
        getProOpt(){
            this.$api.SSO.getProjectList('all',{}).then( d => {
                const arr = Object.entries(d).map(v => ({key:v[0],val:v[1]}) );
                for(let i=0;i<arr.length;i++){
                    // 删除隧道平台
                    if(arr[i].key === 'tunnel'){
                        arr.splice(i,1);
                    }

                    arr[i].keyName = this.transfromProName(arr[i].key);
                    // 过滤-离线部署项目
                    arr[i].val = arr[i].val.filter( v => v.deployFlag !== 1).map( item => (
                        { ...item,devOnly: `${arr[i].key}_${item.projCode}` }
                    ))
                }
                this.proData = arr;
                this.getEchoData();
            })
        },
        // 翻译项目名称
        transfromProName(val){
            let text ='';
            switch(val){
                case 'itgs':
                    text = '智慧路桩';
                    break;
                case 'pah':
                    text = '平安花安全提醒';
                    break;
                case 'slope':
                    text = '边坡健康监测';
                    break;
                case 'bridge':
                    text = '桥梁健康监测';
                    break;
                case 'icps':
                    text = '智能供电包';
                    break;
                case 'melves':
                    text = '气象精灵';
                    break;
                case 'tunnel':
                    text = '隧道管理';
                    break;
                case 'geom':
                    text = '地埋式车检器';
                    break;
                case 'wlms':
                    text = '水位精灵';
                    break;
                case 'wgs':
                    text = '智慧护栏';
                    break;
                case 'bucket':
                    text = '安全哨卫';
                    break;
                case 'jcw':
                    text = '接触网结冰检测';
                    break;
                case 'fog':
                    text = '团雾监测';
                    break;
                case 'swjl':
                    text = '守望精灵';
                    break;
                case 'minicam':
                    text = '低功耗相机';
                    break;
                case 'env':
                    text = '环境检测仓';
                    break;
                default:
                    text = val
            }
            return text
        },
        // 获取用户信息
        getUserInfo(){
            this.$api.SSO.openIdUserBaseInfo(this.OPENID).then( d => {
                if(!d){
                    this.nickname = '用户';
                    this.headImgUrl = 'https://placekitten.com/88/88';
                }else{
                    this.nickname = d.nickname;
                    this.headImgUrl = d.headImgUrl;
                }
            })
        },
        // 获取回显
        getEchoData(){
            this.$api.SSO.echoTempMsgPro(this.OPENID).then( d => {
                this.resTempMsg = d;
            })
        },
        // 提交模板消息项目分配
        saveTempMsg(){
            if(!this.OPENID){
                return this.$toast('获取授权信息失败，请重新登录后重试')
            }
            this.$api.SSO.saveTempMsgPor({
                thirdUniqueAccount:this.OPENID,
                list:this.resTempMsg,
                appId:appId
            }).then( d => {
                this.$toast('保存成功');
                this.tempMsgShow = false;
            })
        },
    },
    mounted(){
        this.OPENID = window.localStorage.getItem('OPENID')
        this.getUserInfo();
        this.getProOpt();
    }
}
</script>

<style lang="scss" scoped>
.setting-home{
    .banner{
        margin-bottom: 10px;
        height: 12rem;
        background:linear-gradient(#0077d4 0%,#276ba0 85%);
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
        text-align: center;
        .hint{
            color: white;font-size: 1rem;padding: 15% 0;
            .logo img{width:65px;height: 65px;border-radius: 50%;margin: 2%;background: #fff;}
        }
    }
    .info{
        padding:0 4%;text-align: left;
        .inner{
            padding:0 2%;background-color: white;border-radius:10px;
        .van-cell{padding: 16px}
        .van-cell__title{font-weight: bold;color: #363636;}
        }
    }
    .content-action{
        padding: 16px;
        text-align: left;
        .handle-area{
            display: flex;
            justify-content: center;
            padding-top: 15px;
            .btn-submit{
                background-color: #2a90e2;
                color:#fff;
                line-height: 38px;
                border-radius: 10px;
                letter-spacing: 2px;
                font-size: .85rem;
                width: 95%;
                text-align: center;
                &:active{
                    opacity: 0.6;
                }
            }
        }
    }
}
</style>
